body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


 .Collapsible__trigger{
  background-color: #fff;
  padding: 1.25rem;
  display: flex;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: -.2px;
  background-image: url(../src/Images/chev.svg);
  background-repeat: no-repeat;
  background-size: 30px;
  margin-top: 0.75rem;
  background-position:center right 15px;
  cursor: pointer;
 }
 .Collapsible__trigger.is-open{
  background-color: #fff;
  padding: 1.25rem;
  display: flex;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: -.2px;
  background-image: url(../src/Images/chev-d.svg);
 }

 .menu .Collapsible__trigger{
  background-color: rgb(34, 11, 59);
  padding: 0.65rem 1.25rem;
  color: #d1d5db;
  display: flex;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -.2px;
  background-image: url(../src/Images/chev-w.svg);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position:center right 18px;
  cursor: pointer;
  margin-top: 0;
 }

.menu .level_two .Collapsible__trigger{
  background-color: rgb(34, 11, 59);
  padding: 0.65rem 2rem !important;
  color: #d1d5db;
  display: flex;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -.2px;
  background-image: url(../src/Images/chev-w.svg);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position:center right 18px;
  cursor: pointer;
  margin-top: 0;
 }
 .menu .Without-Collapsible__trigger{
  background-color: rgb(34, 11, 59);
  padding: 0.65rem 1.25rem;
  color: #d1d5db;
  display: flex;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -.2px;
  background-size: 15px;
  background-position:center right 18px;
  cursor: pointer;
  margin-top: 0;
 }
 .menu .Signout{
  background-color: rgb(34, 11, 59);
  padding: 0.65rem 1.25rem;
  color: #d1d5db;
  display: flex;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -.2px;
  cursor: pointer;
  margin-top: 0;
 }

 .menu .Collapsible__contentInner{
  background-color:  rgb(40, 17, 65);
  /* padding: 0.5rem 1.25rem; */
 }

 .menu .nav-padding{
   padding : 0.6rem 2rem;
 }
 .menu .active{
  background-color:  rgb(53, 13, 95);
  color: white;
 }
.border-1 {
  border-width: 1px;
}

.default-rule{
  fill: #4c1da3;
  display: inline;
  vertical-align: middle;
}

.edit-weightbreak{
  fill: #4c1da3;
}

.uom-input{
  min-width: 3.5rem;
}

/* Toggle CSS */
.toggle-checkbox:checked {
 @apply: right-0 border-green-400;
 right: 0;
 border-color: #68D391;
}
.toggle-checkbox:checked + .toggle-label {
 @apply: bg-green-400;
 background-color: #68D391;
}


.accordion-button:not(.collapsed)::after {
  display: inline-flex;
  /*margin: 1rem;*/
}

.accordion-button::after{
 display: inline-flex;
 margin: 1rem;
 margin-left: unset;
}

.statusDiv:empty{
  display: none;
}

.pdf-iframe{
  height: min(100vh, 80vh);
  /* width: min(50vw, 4000px);  */
}

.required:after {
  content:" *";
  color: red;
}

.font-small{
    font-size: 13px !important;
    padding: 0.65rem 3rem !important;
}

.width-100 *{
  width: 100%;
}