.display-block {
    display: block;
    z-index: 25;
}

.display-none {
    display: none;
}
.width{
    width: calc(10px + 24rem) !important;
}
.table{
    width: 100% !important;
}