
.sticky-col {
    position: -webkit-sticky;
    position: sticky;
}

pre {
    white-space: pre-wrap !important;
    word-break: break-word !important;
}
